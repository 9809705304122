import React, { FunctionComponent } from 'react'

import SearchInputGDS from '@goorm-dev/components/build/SearchInput'

import * as styles from './SearchInput.module.scss'

type SearchInputProps = {
  value: string
  onChange: Function
}

const SearchInput: FunctionComponent<SearchInputProps> = function ({ value, onChange }) {
  return (
    <SearchInputGDS
      className={styles.SearchInput} size="lg"
      inputClassName={styles.SearchInput__input}
      placeholder="키워드로 검색해보세요."
      value={value}
      onChange={onChange}
    />
  )
}

export default SearchInput