import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import ListGroupItem from '_components/Common/ListGroupItem/ListGroupItem'
import ListGroup from '_components/Common/ListGroup/ListGroup'

import { formattedDate } from '_utils/utils'
import { NoticeItemType } from '_types/Notice.types'

import * as styles from './NoticeList.module.scss'

type NoticeListProps = {
  list: NoticeItemType[]
  start: number
  end: number
}

const NoticeList: FunctionComponent<NoticeListProps> = function ({ list, start, end }) {
  return (
    <ListGroup>
      {list.slice(start, end).map(item => (
        <ListGroupItem key={item.id} className={styles.NoticeList__item}>
          <a href={`/notice/${item.strapi_id}`}>
            <h5 className={classNames(styles.NoticeList__item__header, 'mb-1', 'text-gray-900')}>
              {item.title}
            </h5>
            <p className="paragraph-lg text-gray-600">
              {formattedDate(item.date)}
            </p>
          </a>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}

export default NoticeList