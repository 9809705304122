import React, { forwardRef, FunctionComponent, useState, useEffect, useRef } from 'react'
import classNames from 'classnames'

import BasicPagination from '@goorm-dev/components/build/BasicPagination'

import Empty from '_images/Notice/assets/notice-search-empty.svg'
import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import NoticeList from '_components/Notice/NoticeList/NoticeList'
import SearchInput from '_components/Notice/SearchInput/SearchInput'

import { NoticeNodeType, NoticeItemType } from '_types/Notice.types'

import * as styles from './Notice.module.scss'

const ITEM_LIMIT = 10

type NoticeProps = {
  noticeList: NoticeNodeType[]
}

const Notice: FunctionComponent<NoticeProps> = function ({ noticeList }, ref) {
  const [keyword, setKeyword] = useState<string>('');
  const [noticeItems, setNoticeItems] = useState<NoticeItemType[]>([
    {
      id: '',
      strapi_id: 0,
      title: '',
      content: '',
      date: '',
    },
  ])

  // Pagination 관련
  const total = noticeItems.length
  const headerRef = useRef<HTMLDivElement>(null)
  const [page, setPage] = useState<number>(1)
  const [start, setStart] = useState<number>(0)
  const [end, setEnd] = useState<number>(ITEM_LIMIT)

  // const onInitPagination = () => {
  //   setPage(1)
  //   setStart(0)
  //   setEnd(ITEM_LIMIT)
  // }

  const handleScrollMove = () => {
    headerRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handlePagination = (num: string) => {
    const page = Number(num)

    setPage(page)
    setStart((page - 1) * ITEM_LIMIT)
    setEnd((page - 1) * ITEM_LIMIT + ITEM_LIMIT)
    handleScrollMove()
  }

  const onChangeKeyword = (e) => {
    if(keyword !== e.target.value) {
      setKeyword(e.target.value);
    }
  };

  useEffect(() => {
    const filteredNoticeItems = noticeList
      .map(({ node }) => node)
      .filter(({ title }) => title.includes(keyword));
    
    setNoticeItems(filteredNoticeItems)
  }, [keyword]);

  return (
    <SectionWrapper
      ref={ref}
      className={styles.Notice}
    >
      <h1 ref={headerRef} className={styles.Notice__header} >공지사항</h1>
      <SearchInput value={keyword} onChange={onChangeKeyword}  />
      {total > 0 && (
        <>
          <article className={styles.Notice__list}>
            <NoticeList list={noticeItems} start={start} end={end} />
          </article>
          <BasicPagination
            size="md"
            page={page}
            pageCount={Math.ceil(total / ITEM_LIMIT)}
            limitCount={ITEM_LIMIT}
            onPageChangeHandler={handlePagination}
            scrollMove={false}
            className={classNames('justify-content-center d-flex', styles.Notice__pagination)}
          />
        </>
      )}
      {noticeList.length > 0 && total === 0 && (
        <article className={styles.Notice__list__empty}>
          <Empty />
          <p className="text-gray-700">검색 결과가 없습니다.</p>
        </article>
      )}
      {noticeList.length === 0 && (
        <article className={styles.Notice__list__empty}>
          <Empty />
          <p className="text-gray-700">등록된 공지사항이 없습니다.</p>
        </article>
      )}
    </SectionWrapper>
  )
}

export default forwardRef(Notice);