import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'

import PageWrapper from '_components/Common/PageWrapper/PageWrapper'
import Header from '_components/Common/Header/Header'
import Notice from '_containers/Notice/Notice/Notice'

import { NoticePageType } from '_types/Notice.types'

const NoticePage: FunctionComponent<NoticePageType> = function ({
  data: {
    noticeData: { edges: noticeList },
  },
}) {
  return (
    <PageWrapper Header={<Header color='light' />}>
      <Notice noticeList={noticeList} />
    </PageWrapper>
  );
}

export default NoticePage

export const getNoticePageMarkdownData = graphql`
  query getNoticePageMarkdownData {
    noticeData: allStrapiNotice(sort: { order: DESC, fields: strapi_id }) {
      edges {
        node {
          title
          date
          id
          strapi_id
        }
      }
    }
  }
`